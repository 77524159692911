import { useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '.';
import { Question } from '../../models/Question';
import { useSendNotification } from '../notifications';
import { pusher } from '../../services';
import { WEBINAR_PUSHER_EVENTS } from '../../shared';
import { useIsOrganizerOfCurrentWebinar } from 'lib/webinar';
import { useGetCurrentSession } from './session';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { useGetCurrentEvent } from './event';

export type QuestionsParams = {
  session?: string;
  isApproved?: boolean;
  includeOwn?: boolean;
};

const getKey = (webinarId: string, params?: QuestionsParams) => [
  'webinar',
  webinarId,
  'questions',
  params,
];

export interface UpdateQuestionParams {
  isAnswered?: boolean;
  isBeingAnswered?: boolean;
}

const useAddQuestionToQuery = (webinarId: string, params?: QuestionsParams) => {
  const queryClient = useQueryClient();
  return (question: Question) => {
    if (
      (question.session && !params.session) ||
      (!question.session && params.session) ||
      question.session !== params.session
    ) {
      return;
    }
    queryClient.setQueryData<Question[]>(getKey(webinarId, params), (questions = []) => {
      questions = questions.slice();
      const isQuestionExists = questions.find((q) => q._id === question._id);
      if (!isQuestionExists) {
        questions.push(question);
      }
      return questions;
    });
  };
};

const useDeleteQuestionFromQuery = (webinarId: string, params?: QuestionsParams) => {
  const queryClient = useQueryClient();
  return (questionId: string) => {
    queryClient.setQueryData<Question[]>(getKey(webinarId, params), (questions = []) => {
      questions = questions.slice();
      return questions.filter((q) => q._id !== questionId);
    });
  };
};

const useUpdateQuestionInQuery = (webinarId: string, params?: QuestionsParams) => {
  const queryClient = useQueryClient();
  return (questionId: string, updateParams: Question) => {
    if (
      (updateParams.session && !params.session) ||
      (!updateParams.session && params.session) ||
      updateParams.session !== params.session
    ) {
      return;
    }
    queryClient.setQueryData<Question[]>(getKey(webinarId, params), (questions = []) => {
      questions = questions.slice();
      const questionIndex = questions.findIndex((q) => q._id === questionId);
      const newQuestion = { ...questions[questionIndex], ...updateParams };
      if (questionIndex !== -1) {
        questions.splice(questionIndex, 1, {
          ...newQuestion,
          createdBy: questions[questionIndex].createdBy,
        });
      } else {
        questions.push(newQuestion);
      }
      return questions;
    });
  };
};

export const useGetQuestions = (webinarId: string, params?: QuestionsParams) => {
  const apiClient = useApiClient();
  return useQuery(
    getKey(webinarId, params),
    async () => {
      const requestParams = {
        populate: 'createdBy',
        ...params,
      };
      if (!requestParams.session) {
        requestParams.session = 'null';
      }
      const { data } = await apiClient.get<{ data: { questions: Question[] } }>(
        `/webinars/${webinarId}/questions`,
        requestParams,
      );
      return data.questions;
    },
    { enabled: !!webinarId },
  );
};

export const useGetQuestionsForPage = () => {
  const questionsParams = useQuestionParams();
  const { data: webinar } = useGetCurrentEvent();
  return useGetQuestions(webinar._id, questionsParams);
};

export const useQuestionsBeingAnswered = () => {
  const questionsParams = useQuestionParams();
  const { data: webinar } = useGetCurrentEvent();
  const { data: questions } = useGetQuestions(webinar._id, questionsParams);
  if (!questions) return [];
  return questions.filter((q) => q.isBeingAnswered);
};

export interface NewQuestionParams {
  label: string;
  isApproved: boolean;
  isAnswered: boolean;
  session?: string;
}

export const useCreateQuestion = (webinarId: string, params?: QuestionsParams) => {
  const apiClient = useApiClient();
  const { user } = useAuth();
  const notification = useSendNotification();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const addQuestion = useAddQuestionToQuery(webinarId, params);
  return useMutation(
    async (questionParams: NewQuestionParams) => {
      const response = await apiClient.post<{ data: { question: Question } }>(
        `/webinars/${webinarId}/questions?populate=createdBy`,
        questionParams,
      );
      return response.data.question;
    },
    {
      onSuccess: (question) => {
        if (isOrganizer || question.isApproved || question.createdBy._id === user._id) {
          addQuestion(question);
        }
        if (!isOrganizer && !question.isApproved) {
          notification.send({
            title: 'New question',
            message: `"${question.label}" waiting for approval by organizer`,
          });
        }
      },
      onError: () => {
        notification.error({
          message: 'Error adding question',
        });
      },
    },
  );
};

export const useDeleteQuestion = (
  webinarId: string,
  questionId: string,
  params?: QuestionsParams,
) => {
  const apiClient = useApiClient();
  const notification = useSendNotification();
  const deleteQuestionFromQuery = useDeleteQuestionFromQuery(webinarId, params);
  return useMutation(
    async () => {
      await apiClient.delete(`/webinars/${webinarId}/questions/${questionId}`);
      return questionId;
    },
    {
      onError: () => {
        notification.error({
          message: 'Error deleting question',
        });
      },
      onSuccess: (questionId: string) => {
        deleteQuestionFromQuery(questionId);
      },
    },
  );
};

export const useUpdateQuestion = (
  webinarId: string,
  questionId: string,
  params?: QuestionsParams,
) => {
  const apiClient = useApiClient();
  const notification = useSendNotification();
  const updateQuestionInQuery = useUpdateQuestionInQuery(webinarId, params);
  return useMutation(
    async (updateParams: UpdateQuestionParams) => {
      const { data } = await apiClient.patch<{ data: { question: Question } }>(
        `/webinars/${webinarId}/questions/${questionId}`,
        updateParams,
      );
      return data.question;
    },
    {
      onError: () => {
        notification.error({
          message: 'Error updating question',
        });
      },
      onSuccess: (question) => {
        updateQuestionInQuery(questionId, question);
      },
    },
  );
};

export const useQuestionsPusherEvents = (
  webinarId: string,
  options: {
    onApprovedQuestion: (question: Question, sessionTitle?: string) => unknown;
    onQuestionAnswered: (question: Question, sessionTitle?: string) => unknown;
    onQuestionUnAnswered: (question: Question, sessionTitle?: string) => unknown;
    onNonApprovedQuestionAdded: (question: Question, sessionTitle?: string) => unknown;
  },
  params?: QuestionsParams,
) => {
  const addQuestion = useAddQuestionToQuery(webinarId, params);
  const deleteQuestion = useDeleteQuestionFromQuery(webinarId, params);
  const updateQuestion = useUpdateQuestionInQuery(webinarId, params);

  const queryClient = useQueryClient();
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const parameters = useParams();

  useEffect(() => {
    const channel = pusher.subscribe(`private-event-${webinarId}-questions`);
    type PusherEventData = { action: string; question: Question; sessionTitle?: string };

    channel.bind(WEBINAR_PUSHER_EVENTS.QUESTION_CREATED, (data: PusherEventData) => {
      if (data.question.isApproved || isOrganizer) {
        addQuestion(data.question);
      }
      if (data.question.isApproved) {
        options.onApprovedQuestion(data.question, data.sessionTitle);
      } else if (isOrganizer) {
        options.onNonApprovedQuestionAdded(data.question, data.sessionTitle);
      }
    });

    channel.bind(WEBINAR_PUSHER_EVENTS.QUESTION_DELETED, (data: PusherEventData) => {
      deleteQuestion(data.question?._id);
    });

    channel.bind(WEBINAR_PUSHER_EVENTS.QUESTION_UPDATED, (data: PusherEventData) => {
      if (data.question.isApproved) {
        const questions = queryClient.getQueryData<Question[]>(getKey(webinarId, params)) ?? [];
        const question = questions.find((q) => q._id === data.question._id);
        if (!question?.isApproved) {
          options.onApprovedQuestion(data.question, data.sessionTitle);
        }
      }
      if (data.action === 'set_question_to_answered') {
        options.onQuestionAnswered(data.question, data.sessionTitle);
      }

      if (data.action === 'set_question_to_unanswered') {
        options.onQuestionUnAnswered(data.question, data.sessionTitle);
      }

      updateQuestion(data.question._id, data.question);
    });

    return () => {
      channel.unbind();
      channel.unsubscribe();
    };
  }, [
    webinarId,
    params,
    options,
    isOrganizer,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    queryClient,
    parameters.sessionUrlKey,
  ]);
};

export const useQuestionParams = () => {
  const isOrganizer = useIsOrganizerOfCurrentWebinar();
  const { data: session } = useGetCurrentSession();
  const sessionId = session?._id;

  return useMemo(() => {
    const params: QuestionsParams = {};
    if (!isOrganizer) {
      params.isApproved = true;
      params.includeOwn = true;
    }
    params.session = sessionId;
    return params;
  }, [isOrganizer, sessionId]);
};
