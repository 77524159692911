import { forwardRef, useMemo, useState } from 'react';
import { Popover } from '@material-ui/core';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import { WEBINAR_THEMES, replaceURLs } from 'shared';
import { useAuth } from 'contexts/Auth';
import { usePopoverState } from 'components/ui/PopoverBase';
import { Dialog, Zoom } from 'components';
import {
  StyledUserInfo,
  StyledBox,
  StyledAvatar,
  StyledNameWrapper,
  StyledDarkText,
  StyledDropdownItem,
  StyledDropdownList,
  StyledDropdownContent,
  StyledRedDot,
  StyledContainer,
  StyledLabelText,
  StyledDiv,
  StyledListeningBars,
  StyledTime,
  StyledWrapper,
  StyledPollStatusWrapper,
  StyledPill,
  StyledPillWrapper,
  StyledQuestionStatus,
  StyledButtons,
  StyledQuestionText,
} from './question.styled';
import { useDeleteQuestion, useUpdateQuestion } from 'lib/api/qa';
import Button from 'components/ui/Button';

export const Question = ({ question, isOrganizer, theme, webinarId, questionsParams }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [anchorEl, handleOpenDropdown, handleCloseDropdown] = usePopoverState();
  const { user } = useAuth();
  const { _id, label, isApproved, isAnswered, isBeingAnswered, dateCreated, createdBy } = question;

  const { mutateAsync: updateQuestion } = useUpdateQuestion(webinarId, _id, questionsParams);
  const { mutateAsync: deleteQuestion } = useDeleteQuestion(webinarId, _id, questionsParams);

  const ZoomTransition = useMemo(
    () => forwardRef((props, ref) => <Zoom forwardedRef={ref} {...props} />),
    [],
  );
  const name = createdBy._id === user._id ? 'You' : `${createdBy.firstName} ${createdBy.lastName}`;
  return (
    <div>
      <StyledWrapper>
        <StyledUserInfo>
          <StyledBox>
            <StyledAvatar src={createdBy.avatar} />
            <StyledNameWrapper>
              <StyledDarkText>{name}</StyledDarkText>
            </StyledNameWrapper>
          </StyledBox>
          <StyledTime>{formatDistanceToNow(new Date(dateCreated), { addSuffix: true })}</StyledTime>
        </StyledUserInfo>
        {isOrganizer && (
          <>
            <button onClick={handleOpenDropdown}>
              <span>&#8942;</span>
            </button>
            <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseDropdown}>
              <StyledDropdownList>
                <StyledDropdownItem
                  theme={theme.primaryColor}
                  onClick={() => {
                    updateQuestion({ isApproved: !isApproved, isBeingAnswered: false });
                    handleCloseDropdown();
                  }}
                >
                  <StyledDropdownContent>
                    {isApproved ? (
                      <HighlightOffIcon fontSize="small" />
                    ) : (
                      <CheckCircleOutlineIcon fontSize="small" />
                    )}
                    <span>{isApproved ? 'Decline' : 'Approve'}</span>
                  </StyledDropdownContent>
                </StyledDropdownItem>
                <StyledDropdownItem
                  theme={theme.primaryColor}
                  onClick={() => {
                    updateQuestion({ isBeingAnswered: !isBeingAnswered });
                    handleCloseDropdown();
                  }}
                >
                  <StyledDropdownContent>
                    <StyledRedDot online={isBeingAnswered} theme={theme.primaryColor} />
                    <span>{isBeingAnswered ? 'Stop' : 'Start'} Answer</span>
                  </StyledDropdownContent>
                </StyledDropdownItem>
                <StyledDropdownItem
                  theme={theme.primaryColor}
                  onClick={() => {
                    updateQuestion({ isAnswered: !isAnswered, isBeingAnswered: false });
                    handleCloseDropdown();
                  }}
                >
                  <StyledDropdownContent>
                    <QuestionAnswerIcon fontSize="small" />
                    <span>{isAnswered ? 'Not Answered' : 'Answered'}</span>
                  </StyledDropdownContent>
                </StyledDropdownItem>
                <StyledDropdownItem
                  theme={theme.primaryColor}
                  onClick={() => {
                    setShowDeleteDialog(true);
                    handleCloseDropdown();
                  }}
                >
                  <StyledDropdownContent>
                    <DeleteIcon fontSize="small" />
                    <span>Delete</span>
                  </StyledDropdownContent>
                </StyledDropdownItem>
              </StyledDropdownList>
            </Popover>
          </>
        )}
      </StyledWrapper>
      <StyledPollStatusWrapper>
        <StyledPillWrapper>
          <StyledQuestionStatus>
            {isApproved ? (
              <StyledPill size="small" label="Approved" theme={theme.primaryColor} />
            ) : (
              <StyledPill size="small" label="Not approved" theme={WEBINAR_THEMES.LIGHT_GREY} />
            )}
          </StyledQuestionStatus>
          <StyledQuestionStatus>
            {isAnswered && !isBeingAnswered && (
              <StyledPill size="small" label="Answered" theme={theme.primaryColor} />
            )}
            {!isAnswered && !isBeingAnswered && (
              <StyledPill size="small" label="Not answered" theme={WEBINAR_THEMES.LIGHT_GREY} />
            )}
            {isBeingAnswered && (
              <StyledPill
                size="small"
                label="Answering"
                theme={isAnswered ? theme.primaryColor : WEBINAR_THEMES.LIGHT_GREY}
              />
            )}
          </StyledQuestionStatus>
        </StyledPillWrapper>
      </StyledPollStatusWrapper>
      <StyledContainer>
        <Zoom in={isBeingAnswered}>
          <StyledDiv>
            <StyledListeningBars
              coloredBars={[
                theme.primaryColor,
                WEBINAR_THEMES.GREY,
                theme.secondaryColor,
                WEBINAR_THEMES.GREY,
                theme.primaryColor,
                WEBINAR_THEMES.GREY,
              ]}
            />
          </StyledDiv>
        </Zoom>
        <StyledLabelText
          dangerouslySetInnerHTML={{ __html: replaceURLs(label) }}
          theme={theme.primaryColor}
        />
      </StyledContainer>
      {/* Delete question modal */}
      <Dialog
        open={showDeleteDialog}
        TransitionComponent={ZoomTransition}
        onClose={() => setShowDeleteDialog(false)}
        PaperProps={{
          style: {
            borderRadius: 20,
            padding: 40,
            width: 'auto',
          },
        }}
      >
        <div>
          <StyledQuestionText variant="bold">
            Are you sure you want to delete this question?
          </StyledQuestionText>
          <StyledButtons>
            <Button variant="secondary" onClick={() => setShowDeleteDialog(false)}>
              Cancel
            </Button>
            <Button onClick={deleteQuestion}>Confirm</Button>
          </StyledButtons>
        </div>
      </Dialog>
    </div>
  );
};
