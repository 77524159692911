import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    overflow: visible;
  }
`;

const BaseDialog = (props) => {
  const { children, ...rest } = props;

  const DialogProps = {
    children,
    ...rest,
  };

  return <StyledDialog {...DialogProps}>{children}</StyledDialog>;
};

export { BaseDialog };
