import { Dialog } from 'components';
import { FC, useEffect, useMemo, useState } from 'react';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { useGetCurrentEvent } from 'lib/api/event';
import { useWebinarTheme } from 'lib/webinar';
import { StyledSection, StyledQuestion, StyledText } from './webinarStartsSoon.styles';

export const WebinarStartsSoonDialog: FC = () => {
  const [now, setNow] = useState(Date.now());

  const { data: webinar } = useGetCurrentEvent();
  const theme = useWebinarTheme(webinar);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const webinarStartDate = useMemo(() => new Date(webinar.startsAtUtc), [webinar]);

  const timeUntilStartMinutes = useMemo(
    () => differenceInMinutes(webinarStartDate, now),
    [now, webinarStartDate],
  );

  const showWebinarStartsModal = useMemo(() => timeUntilStartMinutes > 30, [timeUntilStartMinutes]);

  return (
    <Dialog
      open={showWebinarStartsModal}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <StyledSection>
        <StyledQuestion color={theme.additionalColors.defaultColor} variant="bold">
          {webinar.title}
        </StyledQuestion>
        <StyledText>Webinar starts in {formatDistanceStrict(webinarStartDate, now)}</StyledText>
      </StyledSection>
    </Dialog>
  );
};
